const images = require.context('../images/fsts/gallery', true);

//TODO: change this when gallery is properly implemented in contentful
export const FstsGalleryImages = images.keys()
  .sort((a, b) => {
    const numA = parseInt(a.match(/(\d+)/)[0], 10);
    const numB = parseInt(b.match(/(\d+)/)[0], 10);

    return numA - numB;
  })
  .map((image, index) => ({
    src: {
      default: images(image).default,
    },
    pl: {
      title: '',
      subtitle: '',
    },
    en: {
      title: '',
      subtitle: '',
    },
  }));
