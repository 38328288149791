import styled from "styled-components";
import { respondTo } from "../../utils/breakpoints";
import { PageSection } from "../../components/global/flexbox";
import { Text } from "../../components/typography";

import Bubble1 from './src/dark-bubble-left.png';
import PartnerBubble from './src/dark-bubble-left-2.svg';
import PartnerBubble2 from './src/light-bubble-right.svg';
import Agenda from './src/agenda.svg';
import Agenda2 from './src/agenda2.svg';
import Where from './src/where.svg'
import Gainz from './src/gainz.svg'
import Gainz2 from './src/gainz2.svg'

import Bubble2 from './src/light-bubble.svg';


export const LogoGrid = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px 32px;
`

export const FSTSHollowCard = styled.div`
  display: flex;
  flex-basis: 100%;
  box-sizing: border-box;
  min-height: 194px;
  padding: 32px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  background-color: rgb(245, 250, 253);
  border-radius: 10px;
  ${respondTo.m`
    flex-basis: 30%;
    max-width:380px;
  `}
  border: 2px solid var(--primary-light, #5FA5C5);
`

export const FSTSHollowTitle = styled.h4`
  color: var(--Dark, #080E14);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
`

export const HollowText = styled(Text)`
  font-weight: 500;
`

export const HeroLogo = styled.img`
  width: 250px;
`

export const HeroSubText = styled.span`
  color: var(--Bright-Light, #6EBDE9);

  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
`

export const EMSImg = styled.img`
  width: 100%;
`

export const LogoCard = styled.a`
  border-radius: 16px;
  width: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border: 1px solid var(--base, #5FA5C5);
  background: #FFF;
  &:hover {
    background: rgba(95, 165, 197, 0.05);
  }
`;

export const LogoImage = styled.img`
  width: 140px;
  height: auto;
  aspect-ratio: 1/1;
`

export const PatronLogo = styled.img`
  width: 180px;
  &.astra {
    width: 100%;
    ${respondTo.m`
      width: 350px;
    `}
  }
  &.uprp {
    width: 240px;
    margin: 12px 0;
  }
  &.full {
    width: 100%;
    ${respondTo.m`
      width: 900px; 
    `}
  }
  height: auto;
  ${respondTo.xs`
    align-self: center;
  `}
`

export const FSTSCardTitle = styled.h4`
  margin-bottom: 0;
  color: var(--mid, #5FA5C5);
  width: 100%;
  text-align: left !important;
  display: block;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  letter-spacing: -0.48px;
`

export const FSTSCardCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

export const FSTSCard = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  border-radius: 32px;
  background: #FFF;
  display: flex;
  padding: 16px 24px;
  align-items: flex-start;
  gap: 16px;
  flex-shrink: 0;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  ${respondTo.m`
  flex-direction: row;
    width: 100%;
  `}
`

export const FSTSCardContainer = styled.div`
  display: flex;
  gap: 20px;
  box-sizing: border-box;
  flex-direction: column;
  > * {
    flex-basis: 50%;
  }
  ${respondTo.m`
    flex-direction: row;
  `}
`

export const CodeCard = styled.div`
  align-self: center;
  padding: 10px 24px;
  gap: 10px;
  font-size: bold;
  border-radius: 32px;
  background: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 27px */
  letter-spacing: -0.36px;
  box-shadow: 0px 0px 40px 0px rgba(18, 102, 140, 0.15);
  > strong {
    color: #5FA5C5;
  }
`

export const StartupSection = styled(PageSection)`
  background-repeat: no-repeat;
  background-position: 
    top left, 
    center right, 
    bottom left;
`

export const ParnterSection = styled(PageSection)`
  background-color: ${(props) => props.bg_col || ''};
  background: url(${PartnerBubble}), url(${Bubble1}), url(${PartnerBubble2});
  background-repeat: no-repeat;
  background-position-y: -40%, 5%, 90%;
  background-position-x: left, right, right;
  ${respondTo.m`
    background-position-y: 30%, 50%, 90%;
  `}
`

export const FSTSPreTitle = styled.span`
  color: var(--base, #5FA5C5);
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

export const FSTSCardRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`

export const FSTSCardImg = styled.div`
  padding: 16px;
  border-radius: 100px;
  background: var(--Bright-Light, #6EBDE9);
`

export const SpeakerSection = styled(PageSection)`
  background: url(${Bubble1}), url(${Bubble2});
  background-color: ${(props) => props.bg_col || ''};
  background-repeat: no-repeat;
  background-position-x: right, left;
  background-position-y: 50%, 20%;
`

export const AgendaSection = styled(PageSection)`
  background-color: #F5FAFD !important;
  background: url(${Agenda}), url(${Agenda2});
  background-repeat: no-repeat;
  background-position-x: left, right;
  background-position-y: 90%, 5%;
  ${respondTo.m`
    background-position-y: 80%, 20%;
  `}
`

export const WhereSection = styled(PageSection)`
  background: url(${Where});
  background-repeat: no-repeat;
  background-color: ${(props) => props.bg_col || ''};

  background-position-x: right;
  background-position-y: 30%;
`

export const GainzSection = styled(PageSection)`
  background: url(${Gainz}), url(${Gainz2});
  background-color: ${(props) => props.bg_col || ''};
  background-repeat: no-repeat;
  background-position-x: right, left;
  background-position-y: 20%, 80%;
`
