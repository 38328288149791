import { graphql } from "gatsby";
import FromScienceToStartup from "../views/from-science-to-startup";


export default FromScienceToStartup

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    gallery: allContentfulGaleriaFromStartupToScience(filter: {node_locale: {eq: "pl-PL"}}) {
      edges {
        node {
          id
          collection {
            description
            title
            file {
              url
            }
            node_locale
          }
        }
      }
    }
  }
`;






