export const DayOne = [
  {
    name: 'Rejestracja uczestników',
    isBreak: true,
    startTime: '2024-11-29T15:00',
    panelLength: '120',
    icon: 'register'
  },

  {
    name: 'Otwarcie wydarzenia',
    isBreak: true,
    startTime: '2024-11-29T17:00',
    panelLength: '15',
    icon: 'opening'
  },

  {
    name: 'Sztuka pisania wniosków grantowych',
    isBreak: false,
    startTime: '2024-11-29T17:15',
    panelLength: '90',
    activityType: 'Warsztat'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-29T19:45',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'Ochrona własności intelektualnej w nauce: Jak publikować, nie narażając swoich innowacji',
    isBreak: false,
    startTime: '2024-11-29T20:00',
    panelLength: '60',
    // eventSpeakers: 'dr Anna Koteja',
    activityType: 'Prelekcja'
  },

  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-29T21:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const DayTwo = [
  {
    name: 'Rejestracja uczestników',
    isBreak: true,
    startTime: '2024-11-30T08:30',
    panelLength: '30',
    icon: 'register'
  },

  {
    name: 'Pitch Perfect - Sztuka prezentowania pomysłów po mistrzowsku',
    isBreak: false,
    startTime: '2024-11-30T09:10',
    panelLength: '210',
    activityType: 'Warsztat'
  },

  {
    name: 'Przerwa',
    isBreak: true,
    startTime: '2024-11-30T12:40',
    panelLength: '20',
    icon: 'coffee'
  },

  {
    name: 'Mosty innowacji: Różne drogi rozwoju jako naukowiec',
    isBreak: false,
    startTime: '2024-11-30T13:00',
    panelLength: '60',
    // eventSpeakers: 'Ilona Gulchak, Tom Slapjums',
    activityType: 'Panel'
  },
  {
    name: 'Przerwa obiadowa',
    isBreak: true,
    startTime: '2024-11-30T14:00',
    panelLength: '60',
    icon: 'coffee'
  },
  {
    name: 'Czy to się sprzeda? Analiza rynku, tworzenie modelu biznesowego oraz produktu',
    isBreak: false,
    startTime: '2024-11-30T15:00',
    panelLength: '120',
    // eventSpeakers:
    //   'Paneliści: Judyta Sobczyk, Maciej Klaczyński, Robert Dwiliński, Magdalena Bohusz-Boguszewska | Moderatorka: Anna Zimny-Zając',
    activityType: 'Warsztat'
  },

  {
    name: 'Od pomysłu do finansowania: Jakie wsparcie możesz otrzymać?',
    isBreak: false,
    startTime: '2024-11-30T17:00',
    panelLength: '90',
    activityType: 'Panel'
  },

  {
    name: 'Zakończenie wydarzenia',
    isBreak: true,
    startTime: '2024-11-30T18:45',
    panelLength: '15',
    icon: 'opening'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-30T19:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const AgendaFSTS = [DayOne, DayTwo];
