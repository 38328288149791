export const DayOne = [
  {
    name: 'Participant registration',
    isBreak: true,
    startTime: '2024-11-29T15:00',
    panelLength: '120',
    icon: 'register'
  },

  {
    name: 'Event opening ',
    isBreak: true,
    startTime: '2024-11-29T17:00',
    panelLength: '15',
    icon: 'opening'
  },

  {
    name: 'Mastering the art of grant application writing',
    isBreak: false,
    startTime: '2024-11-29T17:15',
    panelLength: '90',
    activityType: 'Workshop'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-29T19:45',
    panelLength: '15',
    icon: 'coffee'
  },

  {
    name: 'Safeguarding intellectual property in science: How to publish without compromising your innovations',
    isBreak: false,
    startTime: '2024-11-29T20:00',
    panelLength: '60',
    // eventSpeakers: 'dr Anna Koteja',
    activityType: 'Lecture'
  },

  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-29T21:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const DayTwo = [
  {
    name: 'Participant registration',
    isBreak: true,
    startTime: '2024-11-30T08:30',
    panelLength: '30',
    icon: 'register'
  },

  {
    name: 'Pitch Perfect: How to present ideas like a professional',
    isBreak: false,
    startTime: '2024-11-30T09:10',
    panelLength: '210',
    activityType: 'Workshop'
  },

  {
    name: 'Break',
    isBreak: true,
    startTime: '2024-11-30T12:40',
    panelLength: '20',
    icon: 'coffee'
  },

  {
    name: 'Building bridges for innovation: Diverse career pathways for scientists',
    isBreak: false,
    startTime: '2024-11-30T13:00',
    panelLength: '60',
    // eventSpeakers: 'Ilona Gulchak, Tom Slapjums',
    activityType: 'Panel'
  },
  {
    name: 'Lunch break',
    isBreak: true,
    startTime: '2024-11-30T14:00',
    panelLength: '60',
    icon: 'coffee'
  },
  {
    name: 'Will it sell? Market Analysis, Business Model, and Product Creation',
    isBreak: false,
    startTime: '2024-11-30T15:00',
    panelLength: '120',
    // eventSpeakers:
    //   'Paneliści: Judyta Sobczyk, Maciej Klaczyński, Robert Dwiliński, Magdalena Bohusz-Boguszewska | Moderatorka: Anna Zimny-Zając',
    activityType: 'Workshop'
  },

  {
    name: 'From concept to funding: Exploring support opportunities on the journey to comercialization',
    isBreak: false,
    startTime: '2024-11-30T17:00',
    panelLength: '90',
    activityType: 'Panel'
  },

  {
    name: 'Event closing',
    isBreak: true,
    startTime: '2024-11-30T18:45',
    panelLength: '15',
    icon: 'opening'
  },
  {
    name: 'Networking',
    isBreak: true,
    startTime: '2024-11-30T19:00',
    panelLength: '60',
    icon: 'networking'
  }
];

export const AgendaFSTSEN = [DayOne, DayTwo];
